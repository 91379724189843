@import '../../styles/customMediaQueries.css';

:root {
  --topbarHeight: 72px;
  --asideWidth: 115px;
  --contentWidth: 1077px;
}

/* Topbar */
.topbar {
  position: relative;
}



.fullWidthContainer {
  display: flex;
  @media (--viewportMedium) {
    height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.content {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  /* Size */
  padding: 34px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 24px;
  }

  @media (--viewportMLarge) {
    gap: 36px;
  }
}

.heading {
  width: 100%;
}

.headingText {
  /* Font */
  text-align: center;
  color: var(--marketplaceColor);

  /* Size */
  margin: 17px 0 34px;

  @media (--viewportMLarge) {
    display: none;
  }
}

.heroBoxes {
  flex: 1;
}

.footer {
  @media (--viewportMedium) {
    display: none;
  }
}

.mainContent {
  display: flex;
  justify-content: space-evenly;
}

.mainTitle {
  /* color: #B6030A; */
  font-family: 'Berlingske Serif';
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  line-height: 109%; /* 58.86px */
  letter-spacing: -1.08px;
  width: 80%;
}

.mainDescription {
  /* color: #B6030A; */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%; /* 19.62px */
  letter-spacing: -0.36px;
  margin: 30px 0px;
  width: 80%;
}

.mainDescription2 {
  /* color: #B6030A; */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 109%; /* 19.62px */
  letter-spacing: -0.36px;
  margin-bottom: 97px;
}

.leftContent {
  background-image: url('../../assets/map.png');
  background-size: contain;
  background-position: center; /* Centers the image within the element */
  width: 55%;
  margin: 52px 0px 0px;
}

.iconDesktopContent {
  display: flex;
  text-align: center;
  gap: 10px;
  justify-content: center;
}

.svgContent {
  padding: 24px 32px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 15.9px 0px rgba(186, 186, 186, 0.25);
}

.formContent {
  padding: 30px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: -5px 4px 16.6px 0px rgba(185, 185, 185, 0.25);
  width: 35%;
  margin: 0px 30px;
}

.formContentTitle {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 109%; /* 21.8px */
  letter-spacing: -0.4px;
}

.formContentDescription {
  margin-top: 8px;
  color: #7a7a7a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%; /* 17.44px */
  letter-spacing: -0.32px;
}

.form {
  flex-grow: 1;
  max-width: 800px;
  margin: 20px 0px;
}
